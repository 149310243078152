 body {
   background-color: #cecece;
   height: 100vh;
 }

 code {
   font-size: 1.4rem;
 }

 img {
   width: 220px;
 }

 #root {
   height: 100vh !important;
   background-color: #cecece;

 }


 /* App.css */

 .container {
   display: flex;
   display: -ms-flexbox;
   align-items: center;
   padding-top: 5rem;
   height: 100%;
 }

 .text-center {
   text-align: center;
 }

 .m-auto {
   margin: auto;
 }

 .pt-5 {
   padding-top: 5rem;
 }

 .img-fluid {
   max-width: 100%;
   height: auto;
 }

 .border {
   border: 1px solid rgba(0, 0, 0, 0.125);
 }

 .rounded {
   border-radius: 0.25rem;
 }

 .border-2 {
   border-width: 2px;
 }

 .border-secondary {
   border-color: #6c757d !important;
 }

 .shadow-lg {
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
 }

 .font-monospace {
   font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
 }

 .text-dark {
   color: #343a40 !important;
 }